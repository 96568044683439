<div class="legal" xmlns="http://www.w3.org/1999/html">
    <p>
        Auf Grund der Vorgaben der Europäischen Datenschutz-Grundverordnung (nachfolgend „DSGVO“) sind wir dazu verpflichtet, Sie
        umfassend über die Verarbeitung Ihrer personenbezogenen Daten im Rahmen der Durchführung der Videosprechstunde zu informieren.
        Nachfolgend möchten wir Ihnen gerne die erforderlichen Informationen zur Verfügung stellen.
    </p>
    <p>
        Für den Fall, dass Sie weitergehende Fragen zur Verarbeitung Ihrer personenbezogenen Daten haben, steht Ihnen ergänzend auch unser
        Datenschutzbeauftragter jederzeit sehr gerne zur Verfügung (siehe dazu unter A. IV der nachfolgenden Datenschutzhinweise).
    </p>
    <p>Inhaltsverzeichnis:</p>
    <ol type="I">
        <li>
            <strong>Allgemeine Informationen</strong>
            <ol>
                <li>Wichtige Begriffe</li>
                <li>Geltungsbereich</li>
                <li>Verantwortlicher</li>
                <li>Datenschutzbeauftragter</li>
            </ol>
        </li>
        <li>
            <strong>Die Datenverarbeitungen im Einzelnen</strong>
            <ol>
                <li>Allgemeines zu den Datenverarbeitungen</li>
                <li>Aufrufen unserer Services</li>
                <li>Videosprechstunden: Behandler</li>
                <li>Videosprechstunden: Patienten</li>
                <li>Kontaktaufnahme und Feedback</li>
                <li>Videosprechstunde Siegel und Widget</li>
            </ol>
        </li>
        <li>
            <strong>Betroffenenrechte</strong>
            <ol>
                <li>Widerspruchsrecht</li>
                <li>Auskunftsrecht</li>
                <li>Berichtigungsrecht</li>
                <li>Recht auf Löschung („Recht auf Vergessenwerden“)</li>
                <li>Recht auf Einschränkung der Verarbeitung</li>
                <li>Recht auf Datenübertragbarkeit</li>
                <li>Widerrufsrecht bei Einwilligung</li>
                <li>Beschwerderecht</li>
            </ol>
        </li>
    </ol>
    <br/>
    <ol type="I">
        <li>
            <strong>Allgemeine Informationen</strong>
            <p>
                In diesem Abschnitt der Datenschutzerklärung finden Sie Informationen zum Geltungsbereich, zum Verantwortlichen
                für die Datenverarbeitung, dessen Datenschutzbeauftragtem und zur Datensicherheit. Außerdem erklären wir vorab die
                Bedeutung wichtiger Begriffe, die in der Datenschutzerklärung verwendet werden.<br/>
            </p>
            <ol>
                <li>
                    <strong>Wichtige Begriffe</strong>
                    <p><strong>Browser:</strong> Computerprogramm zur Darstellung von Webseiten (z.B. Chrome, Firefox, Safari)<br/></p>
                    <p><strong>Cookies:</strong> Textdateien, die der aufgerufene Webserver mittels des verwendeten Browsers auf dem
                        Computer des Nutzers platziert. Die gespeicherten Cookie-Informationen können sowohl eine Kennung (Cookie-ID)
                        enthalten, die zur Wiedererkennung dient, als auch inhaltliche Angaben wie Anmeldestatus oder Informationen über
                        besuchte Webseiten. Der Browser sendet die Cookie-Informationen bei späteren, neuen Besuchen dieser Seite mit
                        jeder Anforderung wieder an den Webserver. Die meisten Browser akzeptieren Cookies automatisch. Sie können
                        Cookies mithilfe der Browserfunktionen (zumeist unter „Optionen“ oder „Einstellungen“) verwalten. Dadurch
                        kann das Speichern von Cookies deaktiviert, von Ihrer Zustimmung im Einzelfall abhängig gemacht oder anderweitig
                        eingeschränkt werden. Sie können Cookies auch jederzeit löschen.<br/></p>
                    <p><strong>Drittländer:</strong> Drittländer sind Länder, welche außerhalb der Europäischen Union („EU“)
                        beziehungsweise des Europäischen Wirtschaftsraums („EWR“) liegen. <br/></p>
                    <p><strong>DSGVO:</strong> Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016
                        zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur
                        Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung), abrufbar
                        <a target="_blank" href="https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.DEU">
                            hier
                        </a>.<br/>
                    </p>
                    <p><strong>Personenbezogene Daten:</strong> Alle Informationen, die sich auf eine identifizierte oder identifizierbare
                        natürliche Person beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt,
                        insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer
                        Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der
                        physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser
                        natürlichen Person sind.<br/>
                    </p>
                    <p><strong>Verarbeitung:</strong> Jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede
                        solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation,
                        das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die
                        Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die
                        Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.<br/>
                    </p>
                    <p><strong>Profiling:</strong> Jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht,
                        dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine
                        natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage,
                        Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser
                        natürlichen Person zu analysieren oder vorherzusagen.<br/>
                    </p>
                    <p><strong>Services:</strong> Unsere Angebote, für die diese Datenschutzerklärung gilt (siehe Geltungsbereich).<br/></p>
                    <p><strong>Tracking:</strong> Die Sammlung von Daten und deren Auswertung bzgl. des Verhaltens von Besuchern auf
                        unseren Services.<br/>
                    </p>
                    <p><strong>Tracking-Technologien:</strong> Tracking kann sowohl über die auf unseren Webservern gespeicherten
                        Aktivitätsprotokolle (Logfiles) erfolgen als auch mittels Datenerhebung von Ihrem Endgerät über Pixel,
                        Cookies und ähnliche Tracking-Technologien.<br/>
                    </p>
                    <p><strong>Pixel:</strong>  Pixel werden auch Zählpixel, Trackingpixel, Web Beacons oder Web-Bugs genannt. Es
                        handelt sich um kleine, nicht sichtbare Grafiken in HTML-E-Mails oder auf Webseiten. Wird ein Dokument geöffnet,
                        dann wird dieses kleine Bild von einem Server im Internet geladen, wobei das Herunterladen dort registriert wird.
                        So kann der Betreiber des Servers sehen, ob und wann eine E-Mail geöffnet oder eine Webseite besucht wurde. Meist
                        wird diese Funktion durch den Aufruf eines kleinen Programms (Javascript) realisiert. So können bestimmte Arten von
                        Informatio-nen auf Ihrem Computersystem erkannt und weitergegeben werden, etwa der Inhalt von Cookies, Zeit und
                        Datum des Seitenaufrufs sowie eine Beschreibung der Seite, auf der sich das Zählpixel befindet.<br/>
                    </p>
                </li>
                <li>
                    <strong>Geltungsbereich</strong>
                    <p>Diese Datenschutzhinweise gelten für die nachfolgenden Services:</p>
                    <ul>
                        <li>
                            unser <strong>Onlineangebot „patientus.de“</strong> (Webseite), abrufbar insbesondere unter
                            <a target="_blank" href="https://www.patientus.de">www.patientus.de</a>,
                        </li>
                        <li>
                            unser <strong>Onlineangebot „security.patientus.de“</strong> (Webseite), abrufbar insbesondere unter
                            <a target="_blank" href="https://security.patientus.de">security.patientus.de</a>,
                        </li>
                        <li>
                            unser <strong>Onlineangebot „jameda.patientus.de“</strong> (Webseite) [Patientus White Label Videosprechstunde],
                            abrufbar insbesondere unter <a target="_blank" href="https://jameda.patientus.de">jameda.patientus.de</a>,
                        </li>
                        <li>
                            alle weiteren <strong>Onlineangebot unter den Subdomains von [...].patientus.de
                            (Bsp. (NamederWhitelabel).patientus.de)</strong>
                        </li>
                    </ul>
                    <br/>
                </li>
                <li>
                    <strong>Verantwortlicher</strong>
                    <p>
                        Verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten im Rahmen der Serviceerbringung ist:<br/>
                        <strong>jameda GmbH</strong><br/>
                        <strong>Balanstraße 71a,</strong><br/>
                        <strong>81541 München</strong><br/>
                    </p>
                </li>
                <li>
                    <strong>Datenschutzbeauftragter</strong>
                    <p>
                        Sie können unseren Datenschutzbeauftragten jederzeit über unser Kontaktformular vertrauensvoll kontaktieren:<br/>
                        <a target="_blank" href="https://datenschutzanfrage.de/?source=jameda&lang=de">Datenschutzanfrage-Formular</a><br/>
                        Darüber hinaus können Sie sich auch über die Folgenden Kontaktmöglichkeiten unmittelbar an unseren
                        Datenschutzbeauftragten wenden:<br/>
                        <strong>Postalisch:</strong><br/>
                        jameda GmbH<br/>
                        zu Händen des Fachbereichs Datenschutz<br/>
                        Balanstraße 71a,<br/>
                        81541 München<br/>
                        <strong>E-Mail:</strong> <a href="mailto:datenschutz@jameda.de">datenschutz@jameda.de</a><br/>
                        <strong>Telefon:</strong> 089 2019 44 93<br/>
                        <strong>Fax:</strong> 0800 111 237 686
                    </p>
                </li>
            </ol>
        </li>
        <li>
            <strong>Die Datenverarbeitungen im Einzelnen</strong>
            <p>
                In diesem Abschnitt der Datenschutzerklärung informieren wir Sie im Detail über die Verarbeitung personenbezogener
                Daten im Rahmen unserer Services. Zur besseren Übersichtlichkeit gliedern wir diese Angaben nach bestimmten Funktionalitäten unserer
                Services. Bei der normalen Nutzung der Services können verschiedene Funktionalitäten und damit auch verschiedene Verarbeitungen
                nacheinander oder gleichzeitig zum Tragen kommen.
            </p>
            <ol>
                <li>
                    <strong>Allgemeines zu den Datenverarbeitungen</strong>
                    <p>Für alle nachstehend dargestellten Verarbeitungen gilt, soweit nichts anderes angegeben wird:</p>
                    <ol type="a">
                        <li>
                            <strong>Keine Verpflichtung zur Bereitstellung und Folgen der Nichtbereitstellung</strong>
                            <p>
                                Die Bereitstellung der personenbezogenen Daten ist nicht gesetzlich oder vertraglich vorgeschrieben.
                                Sie sind nicht verpflichtet, Ihre personenbezogenen Daten bereitzustellen. Wir informieren Sie im Rahmen
                                des Eingabeprozesses, wenn die Bereitstellung personenbezogener Daten für den jeweiligen Service
                                erforderlich ist (z.B. durch die Bezeichnung als „Pflichtfeld“). In diesen Fällen kann der gewünschte
                                Service nicht ohne die Verarbeitung Ihrer personenbezogenen Daten erbracht werden. In allen anderen
                                Fällen kann die Nichtbereitstellung Ihrer personenbezogenen Daten Auswirkungen auf Form und Qualität
                                die jeweiligen Services haben.
                            </p>
                        </li>
                        <li>
                            <strong>Einwilligung</strong>
                            <p>
                                In verschiedenen Fällen haben Sie die Möglichkeit, uns im Zusammenhang mit den nachstehend dargestellten
                                Verarbeitungen Ihre Einwilligung zu erteilen. In diesem Fall informieren wir Sie im Zusammenhang mit der
                                Abgabe der jeweiligen Einwilligungserklärung gesondert über alle Modalitäten, über die Reichweite der
                                Einwilligung sowie über die Zwecke, die wir mit diesen Verarbeitungen verfolgen.
                            </p>
                        </li>
                        <li>
                            <strong>Übermittlung personenbezogener Daten an Drittländer</strong>
                            <p>
                                Für den Fall, dass wir Ihre personenbezogenen Daten an Drittländer übermitteln, findet diese Übermittlung
                                ausschließlich unter Einhaltung der gesetzlichen Zulässigkeitsvoraussetzungen statt. Das bedeutet insbesondere,
                                dass wir Ihre personenbezogenen Daten ausschließlich unter Einbeziehung der Voraussetzungen der Art. 44 ff.
                                DSGVO in ein Drittland übermitteln.
                            </p>
                            <p>
                                Grundsätzlich übermitteln wir Ihre personenbezogenen Daten nur an vertrauensvolle Dienstleister in Drittländer
                                mit angemessenem Datenschutzniveau („Angemessenheitsbeschluss“, Art. 45 DSGVO). Liegt für das jeweilige Drittland
                                kein Angemessenheitsbeschluss vor, erfolgt die Übermittlung Ihrer personenbezogenen Daten in der Regel auf Basis
                                geeigneter Garantien im Sinne des Art. 46 DSGVO. Zu diesem Zweck greifen wir insbesondere auf
                                Standarddatenschutzklauseln gem. Art. 46 Abs. 2 lit c. DSGVO zurück, wobei eine Kopie der jeweils abgeschlossenen
                                Vereinbarungen vor Ort eingesehen werden kann.
                            </p>
                            <p>
                                Die Übermittlung Ihrer personenbezogenen Daten an ein Drittland kann darüber hinaus auch zum Zweck der
                                Vertragserfüllung erforderlich sein. Zudem kann die Übermittlung Ihrer personenbezogenen Daten auch zur
                                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich sein oder auf Basis Ihrer
                                ausdrücklichen Einwilligung erfolgen (Art. 49 DSGVO).
                            </p>
                        </li>
                        <li>
                            <strong>Hosting bei externen Dienstleistern</strong>
                            <p>
                                Bei der Verarbeitung Ihrer personenbezogenen Daten setzen wir auch „Hostingdienstleister“ ein, die uns
                                Speicherplatz und Verarbeitungskapazitäten in ihren Rechenzentren zur Verfügung stellen. Die zum Einsatz
                                kommenden „Hostingdienstleister“ verarbeiten Ihre personenbezogenen Daten ausschließlich auf Basis individuell
                                vereinbarter Auftragsverarbeitungsverträge gem. Art. 28 DSGVO, wobei insbesondere sichergestellt ist, dass
                                die Datenverarbeitung ausschließlich auf Grundlage unserer Weisungen erfolgt.
                            </p>
                        </li>
                        <li>
                            <strong>Übermittlung an staatliche Behörden</strong>
                            <p>
                                Wir übermitteln Ihre personenbezogene Daten an staatliche Behörden (einschließlich Strafverfolgungsbehörden),
                                wenn dies zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der wir unterliegen (Art. 6 Abs.
                                1 Satz 1 lit. c DSGVO). Darüber hinaus verarbeiten wir Ihre personenbezogenen Daten auch, wenn die Verarbeitung
                                zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist. Die Verarbeitung Ihrer
                                personenbezogenen Daten erfolgt in diesem Fall auf Basis unserer überwiegenden berechtigten Interessen an der
                                Rechtsdurchsetzung im Sinne des Art. 6 Abs. 1 Satz 1 lit. f DSGVO.
                            </p>
                        </li>
                        <li>
                            <strong>Speicherdauer</strong>
                            <p>
                                In der Rubrik „Speicherdauer“ ist jeweils angegeben, wie lange wir Ihre personenbezogenen Daten für den jeweiligen
                                Zweck beziehungsweise die jeweilige Funktionalität verarbeiten. Sofern die Verarbeitung Ihrer personenbezogenen
                                Daten für den jeweiligen Zweck nicht mehr erforderlich ist, werden wir Ihre personenbezogenen Daten löschen,
                                soweit wir nicht auf Grund gesetzlicher Verpflichtungen zur weitergehenden Aufbewahrung verpflichtet sind.
                            </p>
                        </li>
                        <li>
                            <strong>Bezeichnungen von Datenkategorien</strong>
                            <p>
                                Im Rahmen der Nutzung unserer Services verarbeiten wir regelmäßig die nachfolgend näher bezeichneten Datenkategorien:
                            </p>
                            <ul>
                                <li>
                                    <strong>Accountdaten</strong>
                                </li>
                                <li>
                                    <strong>Personenstammdaten</strong>
                                </li>
                                <li>
                                    <strong>Adressdaten</strong>
                                </li>
                                <li>
                                    <strong>Kontaktdaten</strong>
                                </li>
                                <li>
                                    <strong>Anmeldedaten</strong>
                                </li>
                                <li>
                                    <strong>Zahlungsdaten</strong>
                                </li>
                                <li>
                                    <strong>Zugriffsdaten</strong>
                                </li>
                                <li>
                                    <strong>Profildaten</strong>
                                </li>
                                <li>
                                    <strong>Termindaten Videosprechstunde</strong>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Verarbeitungsvorgänge während der Durchführung der Videosprechstunde</strong>
                            <p>
                                Die Videosprechstunde erfolgt mittels einer Peer-to-Peer-Verbindung zwischen den Endgeräten der
                                Gesprächspartner. Dadurch wird technisch sichergestellt, dass wir keinen Zugriff auf die Inhaltsdaten
                                wie etwa Gespräche, Chatverläufe, Bilder oder sonstige Dokumente haben. In diesem Zusammenhang verarbeiten
                                wir Ihre personenbezogenen Daten lediglich in dem Umfang, in dem dies zur Bereitstellung der
                                Videosprechstunde erforderlich ist.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Aufrufen unserer Services</strong>
                    <p>
                        Nachfolgend beschreiben wir, wie Ihre personenbezogenen Daten beim Aufrufen unserer Services verarbeitet werden
                        (z.B. Laden und Betrachten der Website, Öffnen und Navigation innerhalb der Mobilgeräte-App).
                    </p>
                    <ol type="a">
                        <li>
                            <strong>Zweckbestimmung der Verarbeitung, Rechtsgrundlagen samt Benennung der berechtigten Interessen, Speicherdauer</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Datenkategorie</th>
                                    <th>Zweckbestimmung</th>
                                    <th>Rechtsgrundlage</th>
                                    <th>Ggf. berechtigtes Interesse</th>
                                    <th>Speicherdauer</th>
                                </tr>
                                <tr>
                                    <td>Zugriffsdaten</td>
                                    <td>
                                        Verbindungsaufbau, Darstellung der Inhalte unseres Service, Entdeckung von Angriffen auf unsere
                                        Services anhand ungewöhnlicher Aktivitäten, Fehlerdiagnose.
                                    </td>
                                    <td>
                                        Art. 6 Abs. Satz 1 lit. f DSGVO
                                    </td>
                                    <td>
                                        Sicherstellung der ordnungsgemäßen Funktionalität der Services, Sicherheit von Daten und
                                        Geschäftsprozessen, Verhinderung von Missbrauch, Verhütung von Schäden durch Eingriffe in Informationssysteme.
                                    </td>
                                    <td>Sieben Tage.</td>
                                </tr>
                            </table>
                        </li>
                        <li>
                            <strong>Empfänger der personenbezogenen Daten</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Empfängerkategorie</th>
                                    <th>Datenkategorien</th>
                                    <th>Rechtsgrundlage</th>
                                </tr>
                                <tr>
                                    <td>IT-Sicherheitsdienstleister</td>
                                    <td>Zugriffsdaten</td>
                                    <td>
                                        Art. 6 Abs. 1 Satz 1 lit. f DSGVO.
                                        <small>
                                            Durch den Einsatz externer IT-Dienstleister können wir Integrität und Vertraulichkeit unserer
                                            Services gewährleisten, insbesondere indem Sicherheitslücken identifiziert, Schwachstellen geschlossen
                                            und Angriffe abgewehrt werden.  Darin liegt zugleich unser überwiegendes berechtigtes Interesse begründet.
                                        </small>
                                    </td>
                                </tr>
                            </table>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Videosprechstunden: Behandler</strong>
                    <p>
                        Nachfolgend beschreiben wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie als Behandler einen
                        kostenpflichtigen Account für die Durchführung von Videosprechstunden bei uns erstellen und verwenden:
                    </p>
                    <ol type="a">
                        <li>
                            <strong>Zweckbestimmung der Verarbeitung, Rechtsgrundlagen samt Benennung der berechtigten Interessen, Speicherdauer</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Datenkategorie</th>
                                    <th>Zweckbestimmung</th>
                                    <th>Rechtsgrundlage</th>
                                    <th>Ggf. berechtigtes Interesse</th>
                                    <th>Speicherdauer</th>
                                </tr>
                                <tr>
                                    <td>E-Mail-Adresse</td>
                                    <td>Verifikation der Anmeldung (Double Opt-in-Verfahren)</td>
                                    <td>
                                        Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>
                                        Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)
                                    </td>
                                    <td>Sicherstellung der ordnungsgemäßen Funktionalität der Services, Sicherheit von Daten und Geschäftsprozessen, Verhinderung von Missbrauch, Verhütung von Schäden durch Eingriffe in Informationssysteme.</td>
                                    <td>Dauer des Vertragsverhältnisses</td>
                                </tr>
                                <tr>
                                    <td>Personenstammdaten, Kontaktdaten, Adressdaten</td>
                                    <td>
                                        Identifizierung, Kontrolle der Berechtigung zum Abruf des Angebots, Kontaktaufnahme, Nachvollziehbarkeit
                                        erfolgter Registrierung, Abwicklung von Zahlungen für den Service
                                    </td>
                                    <td>
                                        Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>
                                        Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)
                                    </td>
                                    <td>
                                        Sicherstellung der ordnungsgemäßen Funktionalität der Services,
                                        Sicherheit von Daten und Geschäftsprozessen,
                                        Verhinderung von Missbrauch,
                                        Verhütung von Schäden durch Eingriffe in Informationssysteme.
                                    </td>
                                    <td>
                                        Dauer des Vertragsverhältnisses, soweit eine darüber hinaus gehende Verarbeitung der personenbezogenen
                                        Daten nicht auf Grund gesetzlicher Pflichten, insbesondere des HGB und der AO, erforderlich ist.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vertragsdaten</td>
                                    <td>Dokumentation im Rahmen des Vertragsverhältnisses; Vertragsabwicklung</td>
                                    <td>
                                        Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>
                                        Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)
                                    </td>
                                    <td>
                                        Sicherstellung der ordnungsgemäßen Funktionalität der Services,
                                        Sicherheit von Daten und Geschäftsprozessen,
                                        Verhinderung von Missbrauch,
                                        Verhütung von Schäden durch Eingriffe in Informationssysteme.
                                    </td>
                                    <td>
                                        Dauer des Vertragsverhältnisses, soweit eine darüber hinaus gehende Verarbeitung der personenbezogenen
                                        Daten nicht auf Grund gesetzlicher Pflichten, insbesondere des HGB und der AO, erforderlich ist.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Profildaten</td>
                                    <td>Erbringung unserer Services</td>
                                    <td>
                                        Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>
                                        Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)
                                    </td>
                                    <td>
                                        Sicherstellung der ordnungsgemäßen Funktionalität der Services,
                                        Sicherheit von Daten und Geschäftsprozessen,
                                        Verhinderung von Missbrauch,
                                        Verhütung von Schäden durch Eingriffe in Informationssysteme.
                                    </td>
                                    <td>
                                        Dauer des Vertragsverhältnisses, soweit eine darüber hinaus gehende Verarbeitung der personenbezogenen
                                        Daten nicht auf Grund gesetzlicher Pflichten, insbesondere des HGB und der AO, erforderlich ist.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Termindaten</td>
                                    <td>Erbringung unserer Services</td>
                                    <td>
                                        Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>
                                        Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)
                                    </td>
                                    <td>
                                        Sicherstellung der ordnungsgemäßen Funktionalität der Services,
                                        Sicherheit von Daten und Geschäftsprozessen,
                                        Verhinderung von Missbrauch,
                                        Verhütung von Schäden durch Eingriffe in Informationssysteme.
                                    </td>
                                    <td>
                                        3 Monate nach Durchführung des Termins, soweit eine darüber hinaus gehende Verarbeitung der personenbezogenen
                                        Daten nicht auf Grund gesetzlicher Pflichten, insbesondere des HGB und der AO, erforderlich ist.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Zahlungsdaten</td>
                                    <td>Abwicklung von Bestellungen und Zahlungen zur Erbringung unserer Services</td>
                                    <td>
                                        Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>
                                        Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)
                                    </td>
                                    <td>
                                        Sicherstellung der ordnungsgemäßen Funktionalität der Services,
                                        Sicherheit von Daten und Geschäftsprozessen,
                                        Verhinderung von Missbrauch,
                                        Verhütung von Schäden durch Eingriffe in Informationssysteme.
                                    </td>
                                    <td>
                                        Dauer des Vertragsverhältnisses, soweit eine darüber hinaus gehende Verarbeitung der personenbezogenen
                                        Daten nicht auf Grund gesetzlicher Pflichten, insbesondere des HGB und der AO, erforderlich ist.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Anmeldedaten, Zugriffsdaten, Nutzungsdaten</td>
                                    <td>
                                        Verbindungsaufbau, Darstellung der Inhalte des Service, Entdeckung von Angriffen auf unsere Seite
                                        anhand ungewöhnlicher Aktivitäten, Fehlerdiagnose, Erbringung unserer Services
                                    </td>
                                    <td>
                                        Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>
                                        Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)
                                    </td>
                                    <td>
                                        Sicherstellung der ordnungsgemäßen Funktion der Services,
                                        Sicherheit von personenbezogenen Daten und Geschäftsprozessen,
                                        Verhinderung von Missbrauch,
                                        Verhütung von Schäden durch Eingriffe in Informationssysteme,
                                        Verbesserung unserer Services.
                                    </td>
                                    <td>
                                        Dauer des Vertragsverhältnisses, soweit eine darüber hinaus gehende Verarbeitung der personenbezogenen
                                        Daten nicht auf Grund gesetzlicher Pflichten, insbesondere des HGB und der AO, erforderlich ist.
                                    </td>
                                </tr>
                            </table>
                        </li>
                        <li>
                            <strong>Empfänger der personenbezogenen Daten</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Empfängerkategorie</th>
                                    <th>Betroffene Daten</th>
                                </tr>
                                <tr>
                                    <td>IT-Dienstleister für Kundenverwaltungssystem (CRM)</td>
                                    <td>Personenstammdaten; Adressdaten; Kontaktdaten; Zahlungsdaten; Vertragsdaten</td>
                                </tr>
                                <tr>
                                    <td>IT-Dienstleister für Buchhaltung</td>
                                    <td>Personenstammdaten; Adressdaten; Kontaktdaten; Zahlungsdaten; Vertragsdaten</td>
                                </tr>
                            </table>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Videosprechstunden: Patienten</strong>
                    <p>
                        Nachfolgend beschreiben wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie als Patient einen sog.
                        einmaligen Termin, welcher über unsere Plattform von Ihrem Behandler für Sie persönlich angelegt wird, wahrnehmen
                        oder einen sog. online buchbaren Termin in der Videosprechstunde mit einem Behandler auf unserer Plattform buchen
                        und wahrnehmen.
                    </p>
                    <ol type="a">
                        <li>
                            <strong>Zweckbestimmung der Datenverarbeitung und Rechtsgrundlage sowie ggf. berechtigte Interessen, Speicherdauer</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Datenkategorie</th>
                                    <th>Zweckbestimmung</th>
                                    <th>Rechtsgrundlage</th>
                                    <th>Ggf. berechtigtes Interesse</th>
                                    <th>Speicherdauer</th>
                                </tr>
                                <tr>
                                    <td>Name, Vorname, Geburtsdatum, E-Mail-Adresse</td>
                                    <td>
                                        Erbringung unserer Services für Patienten, Identifizierung, Kontrolle der Berechtigung zum Abruf
                                        des Angebots, vorgeschriebene Dokumentation durch Behandler
                                    </td>
                                    <td>Art. 9 Abs. 2 lit. a DSGVO</td>
                                    <td></td>
                                    <td>
                                        Grundsätzlich löschen wir Ihre personenbezogenen Daten drei Monate nach Durchführung des Termins,
                                        soweit Sie keinen Widerruf hinsichtlich der Ihrerseits erteilten Einwilligung erklären. Eine darüber
                                        hinaus gehende Speicherung Ihrer personenbezogenen Daten erfolgt ausschließlich, falls diese auf Grund
                                        gesetzlicher Pflichten, insbesondere des HGB und der AO, erforderlich ist.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Zugriffsdaten, Anmeldedaten</td>
                                    <td>
                                        Erbringung unserer Services, vorgeschriebene Dokumentation durch Behandler, Vertragserfüllung, Darstellung
                                        der Inhalte des Service, Entdeckung von Angriffen auf unsere Seite anhand ungewöhnlicher Aktivitäten,
                                        Fehlerdiagnose
                                    </td>
                                    <td>Art.9 Abs.2 lit.a DSGVO, Art. 6 Abs.1 S.1 lit.f Alt. 2 DSGVO</td>
                                    <td>
                                        Durch die Verarbeitung der benannten Datenkategorien wird die ordnungsgemäße Funktionalität sowie insbesondere
                                        die Sicherheit unserer Services gewährleistet. Darüber hinaus dient die Verarbeitung Ihrer personenbezogenen
                                        Daten in diesem Zusammenhang der Missbrauchsverhinderung der Verhütung von Schadfällen sowie der Optimierung
                                        unserer Services in Ihrem Interesse. Darin liegt zugleich auch unser überwiegendes Interesse an der
                                        Verarbeitung Ihrer personenbezogenen Daten begründet.
                                    </td>
                                    <td>Sieben Tage.</td>
                                </tr>
                                <tr>
                                    <td>Termindaten</td>
                                    <td>
                                        Erbringung unserer Services, Dokumentation im Rahmen des Vertragsverhältnisses, vorgeschriebene Dokumentation
                                        durch Behandler, Vertragserfüllung
                                    </td>
                                    <td>Art. 9 Abs. 2 lit. a DSGVO</td>
                                    <td></td>
                                    <td>
                                        Grundsätzlich löschen wir Ihre personenbezogenen Daten drei Monate nach Durchführung des Termins, soweit
                                        Sie keinen Widerruf hinsichtlich der Ihrerseits erteilten Einwilligung erklären. Eine darüber hinaus gehende
                                        Speicherung Ihrer personenbezogenen Daten erfolgt ausschließlich, falls diese auf Grund gesetzlicher
                                        Pflichten, insbesondere des HGB und der AO, erforderlich ist.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nur bei online buchbarem Termin: Zahlungsdaten</td>
                                    <td>Abwicklung von Bestellungen und Zahlungen zur Erbringung unserer Services</td>
                                    <td>
                                        Art. 6 Abs.1 S.1 lit.b Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer selbst der Kunde ist)<br/><br/>
                                        Art. 6 Abs.1 S.1 lit.f Alt.1 DSGVO<br/>
                                        (im Falle, dass der medizinische Leistungserbringer ein Angestellter des Kunden z.B. Krankenhaus ist.)
                                    </td>
                                    <td>
                                        Durch die Verarbeitung der benannten Datenkategorien wird die ordnungsgemäße Funktionalität sowie insbesondere die
                                        Sicherheit unserer Services gewährleistet. Darüber hinaus dient die Verarbeitung Ihrer
                                        personenbezogenen Daten in diesem Zusammenhang der Missbrauchsverhinderung der Verhütung von
                                        Schadfällen sowie der Optimierung unserer Services in Ihrem Interesse. Darin liegt zugleich auch unser
                                        überwiegendes Interesse an der Verarbeitung Ihrer personenbezogenen Daten begründet.
                                    </td>
                                    <td>
                                        Dauer des Vertragsverhältnisses, soweit eine darüber hinaus gehende Verarbeitung der personenbezogenen
                                        Daten nicht auf Grund gesetzlicher Pflichten, insbesondere des HGB und der AO, erforderlich ist.
                                    </td>
                                </tr>
                            </table>
                        </li>
                        <li>
                            <strong>Empfänger der personenbezogenen Daten</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Empfängerkategorie</th>
                                    <th>Betroffene Daten</th>
                                    <th>Rechtsgrundlage</th>
                                </tr>
                                <tr>
                                    <td>
                                        Behandler, bei dem Sie den Termin wahrnehmen sowie soweit erforderlich weitere Leistungserbringer im
                                        Zusammenhang mit der Durchführung der Videosprechstunde
                                    </td>
                                    <td>
                                        Name, Vorname, Geburtsdatum, E-Mail-Adresse, Termindaten Allgemein, Termindaten EBM, ggf.
                                        (nur bei online buchbarem Termin) Zahlungsdaten.
                                    </td>
                                    <td>Art. 9 Abs. 2 lit. a DSGVO</td>
                                </tr>
                            </table>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Kontaktaufnahme und Feedback</strong>
                    <p>
                        Nachfolgend beschreiben wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn sie sich an unseren
                        Kundenservice wenden (z.B. über ein Online-Kontaktformular oder via Telefon) und/oder an Umfragen zur Kunden-
                        bzw. Nutzerzufriedenheit teilnehmen:
                    </p>
                    <ol type="a">
                        <li>
                            <strong>Zweckbestimmung der Datenverarbeitung und Rechtsgrundlage sowie ggf. berechtigte Interessen, Speicherdauer</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Datenkategorie</th>
                                    <th>Zweckbestimmung</th>
                                    <th>Rechtsgrundlage</th>
                                    <th>Ggf. berechtigtes Interesse</th>
                                    <th>Speicherdauer</th>
                                </tr>
                                <tr>
                                    <td>Personenstammdaten, Kontaktdaten, Inhalte der Anfragen/Beschwerden, Zugriffsdaten</td>
                                    <td>
                                        Im Rahmen der Bearbeitung Ihrer Kundenanfrage ist die Verarbeitung Ihrer personenbezogenen Daten
                                        zwingend erforderlich. Durch die Bearbeitung Ihrer personenbezogenen Daten möchten wir Ihre Anfrage
                                        bearbeiten und zur Verbesserung unserer Services beitragen. Soweit es im Zusammenhang mit der
                                        Bearbeitung Ihrer Anfrage zu einem ergänzenden Vertragsabschluss kommt, dient die Bearbeitung
                                        Ihrer Anfrage zugleich auch der Vertragsanbahnung respektive Vertragserfüllung.
                                    </td>
                                    <td>
                                        Art. 6 Abs. 1 Satz 1 lit. a DSGVO<br/>
                                        Art. 6 Abs. 1 Satz 1 lit. b DSGVO<br/>
                                        Art. 6 Abs. 1 Satz 1 lit. f DSGVO
                                    </td>
                                    <td>
                                        Durch unseren Kundenservice sowie durch die Bearbeitung Ihrer Kundenanfrage möchten wir maßgeblich
                                        dazu beitragen, das Kundenverhältnis mit Ihnen zu erhalten beziehungsweise zu stärken. Darin liegt
                                        zugleich unser überwiegendes berechtigtes Interesse begründet.
                                    </td>
                                    <td>
                                        1 Jahr nach abgeschlossener Bearbeitung der Anfrage, soweit eine darüber hinaus gehende Verarbeitung
                                        der personenbezogenen Daten nicht auf Grund gesetzlicher Pflichten, insbesondere des HGB und der AO,
                                        erforderlich ist.
                                    </td>
                                </tr>
                            </table>
                        </li>
                        <li>
                            <strong>Empfänger von personenbezogenen Daten</strong>
                            <table style="width:100%">
                                <tr>
                                    <th>Empfängerkategorie</th>
                                    <th>Betroffene Daten</th>
                                </tr>
                                <tr>
                                    <td>IT-Dienstleister für Kundenverwaltungssystem (CRM)</td>
                                    <td>Personenstammdaten; Kontaktdaten; Inhalte der Anfragen/Beschwerden; Inhalt des Feedbacks</td>
                                </tr>
                                <tr>
                                    <td>Telefonprovider</td>
                                    <td>Telefonnummer; Verbindungsdaten</td>
                                </tr>
                            </table>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Videosprechstunde Siegel und Widget</strong>
                    <p>
                        In diesem Abschnitt beschreiben wir, wie wir Ihre personenbezogenen Daten verarbeiten werden, wenn Sie die
                        Homepage eines Behandlers (z.B. eines Arztes oder Psychotherapeuten) besuchen, der eines unserer Siegel oder unserer
                        Widgets eingebunden hat.
                        Ein Widget ist ein kleines Fenster, das veränderliche Informationen anzeigt. Auch unser Siegel funktioniert in ähnlicher
                        Weise, d.h. es sieht nicht immer gleich aus, sondern die Anzeige ändert sich. Dabei wird der entsprechende Inhalt zwar
                        auf der Homepage des Behandlers dargestellt, er wird aber in diesem Moment von unseren Servern abgerufen. Nur so kann
                        immer der aktuelle Inhalt gezeigt werden, vor allem die jeweils aktuelle Bewertung. Dafür muss eine Datenverbindung
                        von der aufgerufenen Seite (Homepage des Behandlers) zu uns aufgebaut werden und wir erhalten gewisse technische Daten
                        (Zugriffsdaten), die nötig sind, damit der Inhalt ausgeliefert werden kann. Diese Daten werden aber nur für die
                        Bereitstellung des Inhalts verwendet und nicht gespeichert oder anderweitig genutzt. Beim Anklicken des Siegels werden
                        Sie gegebenenfalls auf unsere Webseite weitergeleitet.
                    </p>
                    <p>
                        Der Behandler verfolgt mit der Einbindung den Zweck und das berechtigte Interesse, aktuelle und korrekte Inhalte
                        auf seiner Homepage darzustellen. Rechtsgrundlage ist Art. 6 Abs. 1 Satz 1 lit. f DSGVO. Eine Speicherung von Zugriffsdaten
                        erfolgt durch den Verantwortlichen der Behandlerhomepage aufgrund dieser Einbindung nicht. Bezüglich der sonstigen
                        Datenverarbeitung durch die Behandlerhomepage können Sie sich über die dort abrufbare Datenschutzerklärung informieren.
                        Aufgrund der Einbindung erfolgt, wie oben beschrieben, eine Datenübermittlung von Zugriffsdaten an uns sowie in der
                        Folge an unsere Hostingdiensleister. Die Daten werden jedoch ausschließlich zur Bereitstellung der Inhalte genutzt und
                        danach sofort wieder gelöscht.
                    </p>
                </li>
            </ol>
        </li>
        <li>
            <strong>Betroffenenrechte</strong>
            <ol>
                <li>
                    <strong>Widerspruchsrecht</strong>
                    <p>
                        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit mit Wirkung für
                        die Zukunft gegen die Verarbeitung Sie betreffender personenbezogener Daten, die gemäß Art. 6 Abs. 1 Satz 1 lit.
                        e beziehungsweise lit. f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen
                        gestütztes Profiling.
                    </p>
                    <p>
                        Das Widerspruchsrecht können Sie kostenfrei ausüben. Um Ihr Anliegen schneller zu bearbeiten, nutzen Sie bitte
                        bevorzugt unser Formular unter dem folgenden Link: <br/>
                        Zur Datenschutzanfrage
                        <a target="_blank" href="https://datenschutzanfrage.de/?source=jameda&lang=de">Datenschutzanfrage-Formular</a>
                    </p>
                    <p>Alternativ können Sie uns u.a. über die unter I. 3 genannten Kontaktdaten erreichen.</p>
                </li>
                <li>
                    <strong>Auskunftsrecht</strong>
                    <p>
                        Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten
                        verarbeitet werden, sowie ggf. auf Auskunft über diese personenbezogenen Daten und die weiteren in
                        <a target="_blank" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE#d1e2528-1-1">
                            Art. 15 DSGVO</a> aufgeführten Informationen.
                    </p>
                </li>
                <li>
                    <strong>Berichtigungsrecht</strong>
                    <p>
                        Sie haben das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu
                        verlangen (Art. 16 DSGVO). Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die
                        Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.
                    </p>
                </li>
                <li>
                    <strong>Recht auf Löschung („Recht auf Vergessenwerden“)</strong>
                    <p>
                        Sie haben das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden,
                        sofern einer der in Art. 17 Abs. 1 DSGVO genannten Gründe zutrifft und die Verarbeitung nicht für einen der in Art. 17
                        Abs. 3 DSGVO geregelten Zwecke erforderlich ist.
                    </p>
                </li>
                <li>
                    <strong>Recht auf Einschränkung der Verarbeitung</strong>
                    <p>
                        Sie sind berechtigt, eine Einschränkung bei der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, wenn eine der
                        in Art. 18 Abs. 1 lit. a bis d DSGVO geregelten Voraussetzungen gegeben ist.
                    </p>
                </li>
                <li>
                    <strong>Recht auf Datenübertragbarkeit</strong>
                    <p>
                        Sie haben unter den in Art. 20 Abs. 1 DSGVO genannten Voraussetzungen das Recht, die Sie betreffenden personenbezogenen
                        Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten,
                        und das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln. Bei der Ausübung
                        des Rechts auf Datenübertragbarkeit haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten direkt von
                        uns an eine andere verantwortliche Stelle übermittelt werden, soweit dies technisch machbar ist.
                    </p>
                </li>
                <li>
                    <strong>Widerrufsrecht bei Einwilligung</strong>
                    <p>
                        Soweit die Verarbeitung auf Ihrer Einwilligung beruht, haben Sie das Recht, die Einwilligung jederzeit zu widerrufen.
                        Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird dadurch nicht berührt.
                    </p>
                </li>
                <li>
                    <strong>Beschwerderecht</strong>
                    <p>
                        Sie haben ein Beschwerderecht bei der für unser Unternehmen zuständigen Aufsichtsbehörde. Die für unser Unternehmen
                        zuständige Aufsichtsbehörde ist:
                    </p>
                    <p>
                        <strong>Bayerisches Landesamt für Datenschutzaufsicht,</strong><br/>
                        Promenade 18, 91522 Ansbach,<br/>
                        <a target="_blank" href="https://www.lda.bayern.de/de/index.html">https://www.lda.bayern.de</a><br/>
                        <strong>Telefon:</strong> +49 (0) 981 180093-0<br/>
                        <strong>Telefax:</strong> +49 (0) 981 180093-800<br/>
                        <strong>E-Mail:</strong> <a href="mailto:poststelle@lda.bayern.de"> poststelle@lda.bayern.de</a>
                    </p>
                </li>
            </ol>
        </li>
    </ol>
</div>
