import { Genders, GenderType, getGenderByName } from '../../../../utils/genders';
import { Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
    AfterViewChecked,
    ChangeDetectorRef
} from '@angular/core';
import { ProfileSettingState } from '../profile-tab/profile-tab.component';
import { Profile, ProfileSettings } from '../../../../profile/store/profile.entity';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProfileUpdateFormBuilder } from './profile-update.form-builder';
import { InvalidDirective } from '../../../../../forms/directives/invalid.directive';
import { QualificationDropDownSettings } from '../../../../utils/qualifications';
import { ProfileService } from '../../../../profile/providers/profile.service';
import { SpecialityService, SpecialityType } from '../../../../../entities/speciality/provider/speciality.service';
import {delay, take} from 'rxjs/operators';

@Component({
    selector: 'app-profile-update',
    templateUrl: './profile-update.component.html',
    styleUrls: ['./profile-update.component.scss']
})
export class ProfileUpdateComponent implements OnInit, AfterViewChecked {
    @ViewChildren(InvalidDirective) invalidDirectives: QueryList<InvalidDirective>;
    @Input() profile: Profile;
    @Output() profileSettingsStateEmitter = new EventEmitter<ProfileSettingState>();

    public genders: GenderType[];
    public specialities: SpecialityType[];
    public profileUpdateForm: UntypedFormGroup;
    public specialityDropDownSettings;
    public isSubmitting = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private specialityService: SpecialityService,
        private profileService: ProfileService,
        private cdRef: ChangeDetectorRef) {
        this.specialityDropDownSettings = {...QualificationDropDownSettings, classes: 'gray-multi-select-default'};
        this.profileService = profileService;
    }

    ngOnInit() {
        this.profileUpdateForm = ProfileUpdateFormBuilder.build(this.formBuilder);
        this.genders = Genders;

        this.specialityService.getSpecialties().pipe(take(1)).subscribe(sp => {
            this.specialities = sp;
            this.profileUpdateForm.controls['specialities'].setValue(this.profile.specialities);
        });

        this.setDefaults();
    }
    // TODO Remove when angular2-multiselect-dropdown fixes the bug in the new release
    // https://github.com/CuppaLabs/angular2-multiselect-dropdown/issues/544
    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    public onSubmit(): void {
        Object.keys(this.profileUpdateForm.controls).map(key => {
            this.profileUpdateForm.get(key).markAsDirty();
        });
        this.invalidDirectives.map((directive) => {
            directive.triggerTooltip();
        });

        if (this.profileUpdateForm.valid) {
            this.isSubmitting = true;
            this.profileService.updateProfileSettings(this.getPayload()).subscribe(profile => {
                this.changeView();
            });
        }
    }

    public setDefaults() {

        if (this.profile.hasOwnProperty('salutation')) {
            this.profileUpdateForm.controls['gender'].setValue(getGenderByName(this.profile.salutation));
        }

        if (this.profile.hasOwnProperty('details') && this.profile.details.hasOwnProperty('about')) {
            this.profileUpdateForm.controls['about'].setValue(this.profile.details.about);
        }

        if (this.profile.hasOwnProperty('title')) {
            this.profileUpdateForm.controls['title'].setValue(this.profile.title);
        }
    }

    public changeView() {
        this.profileSettingsStateEmitter.emit(ProfileSettingState.PROFILE_SETTINGS_VIEW);
    }

    public isSelected(genderName): boolean {
        const selected = this.profileUpdateForm.controls['gender'].value;
        if (!selected) {
            return false;
        }

        return selected.name === genderName;
    }

    public onChangeSpecialities() {
        if (this.profileUpdateForm.controls['specialities'].untouched) {
            this.specialityDropDownSettings = {...QualificationDropDownSettings, classes: 'gray-multi-select-untouched'};
            return;
        }

        this.specialityDropDownSettings = {...QualificationDropDownSettings, classes: 'gray-multi-select-touched'};
    }

    private getPayload(): ProfileSettings {
        const payload = <ProfileSettings>{
            id: 1,
            title: this.profileUpdateForm.controls['title'].value,
            salutation: this.profileUpdateForm.controls['gender'].value,
            details: {
                about: this.profileUpdateForm.controls['about'].value,
            },
            specialities: this.profileUpdateForm.controls['specialities'].value,
        };

        return payload;
    }
}
