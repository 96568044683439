import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../../../forms/validators/custom-validator';
import { COUNTRY_GERMANY } from '../../../../../entities/payment-country/provider/country.service';

export class CreateLegalUserFormBuilder {

    public static build(formBuilder: UntypedFormBuilder): UntypedFormGroup {
        return formBuilder.group({
            firstName: ['', Validators.compose([Validators.required])],
            lastName: ['', Validators.compose([Validators.required])],
            email: ['', Validators.compose([Validators.required, CustomValidators.email()])],
            country: [{value: COUNTRY_GERMANY, disabled: true},
                Validators.compose([
                    CustomValidators.countryGermany()
                ])
            ],
            nationality: [COUNTRY_GERMANY, Validators.compose([Validators.required])],
            birthday: formBuilder.group(
                {
                    birthDay: ['', Validators.compose([
                        CustomValidators.validDate({day: 'birthDay', month: 'birthMonth', year: 'birthYear'}),
                        Validators.required
                    ])],
                    birthMonth: ['', Validators.compose([
                        CustomValidators.validDate({day: 'birthDay', month: 'birthMonth', year: 'birthYear'}),
                        Validators.required
                    ])],
                    birthYear: ['', Validators.compose([
                        CustomValidators.validDate({day: 'birthDay', month: 'birthMonth', year: 'birthYear'}),
                        Validators.required
                    ])],
                }
            ),
            companyName: ['', Validators.compose([Validators.required])],
        });
    }
}
