import { Component, HostListener } from "@angular/core";
import { Observable } from "rxjs";
import { BrandingService } from "../../../branding/providers/branding.service";
import { BrandingState } from "../../../branding/store/branding.state";
import { fastFadeInOut } from "../../../../animations";

@Component({
    selector: "app-logo",
    templateUrl: "./logo.component.html",
    styleUrls: ["./logo.component.scss"],
    animations: [fastFadeInOut],
})
export class LogoComponent {
    public brandingSettings$: Observable<BrandingState>;
    public mouseMoved$ = true;
    mouseInactiveTimer: ReturnType<typeof setTimeout>;

    @HostListener("document:mousemove", ["$event"])
    onMouseMove() {
        clearTimeout(this.mouseInactiveTimer);
        this.mouseMoved$ = true;

        this.mouseInactiveTimer = setTimeout(() => {
            this.mouseMoved$ = false;
        }, 3000);
    }

    constructor(private brandingService: BrandingService) {
        this.brandingSettings$ =
            this.brandingService.getBrandingSettingsFromStore();
    }
}
