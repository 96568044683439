import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

export class ProfileUpdateFormBuilder {

    public static build(formBuilder: UntypedFormBuilder): UntypedFormGroup {
        return formBuilder.group({
            gender: [''],
            title: [''],
            about: [''],
            specialities: ['']
        });
    }
}
