import { AppState as RootAppState } from "../../../providers/store/app.state";
type Timer = ReturnType<typeof setInterval>;

export interface SignalConnectionReadyState {
    isRemoteConnectionReady: boolean;
    isLocalConnectionReady: boolean;
}

export interface SignalState {
    myTokens: string[];
    userTokens: string[];
    activeUserToken: string;
    activePollingTimer: Timer;
    ready: SignalConnectionReadyState;
}

export class SignalConnectionReadyInitialState
    implements SignalConnectionReadyState
{
    // At the moment we do nothing with isRemoteConnectionReady state. we get this from the old application. to determine when a peer is
    // online we use @OnlineStateConnector.onIsOnlineAndReady
    public isRemoteConnectionReady = false;
    public isLocalConnectionReady = false;
}

export class SignalInitialState implements SignalState {
    public myTokens: string[] = null;
    public userTokens: string[] = null;
    public activeUserToken: string = null;
    public activePollingTimer: Timer = null;
    public ready: SignalConnectionReadyState =
        new SignalConnectionReadyInitialState();
}

export interface AppState extends RootAppState {
    signal: SignalState;
}
