import { COUNTRY_GERMANY } from '../../entities/payment-country/provider/country.service';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

const PASSWORD_REGEX = /^(?=\S*[a-zäöüß])(?=\S*[A-ZÄÖÜ])(?=\S*\d)(?=\S*[^\w\säöüßÄÖÜ])\S{4,}$/;
// eslint-disable-next-line max-len
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^[0-9]{6,20}$/;
const MONTH_REGEX = /^(0?[1-9]|1[012])$/;
const DAY_REGEX = /^(0?[1-9]|1[031])$/;
const YEAR_REGEX = /^\d{4}$/;
const IBAN_REGEX = /^[a-zA-Z]{2}\d{2}\s*(\w{4}\s*){2,7}\w{1,4}\s*$/;
const BIC_REGEX = /^[a-zA-Z]{6}\w{2}(\w{3})?$/;
const BANK_ACC_TEXT_FIELDS_REGEX = /^.{1,255}$/;

export interface BirthdayStruct {
    day: string;
    month: string;
    year: string;
}

export class CustomValidators {
    static confirm(fieldName: string): ValidatorFn {
        let fcfirst: AbstractControl;
        let fcSecond: AbstractControl;

        return (control: AbstractControl): ValidationErrors | null => {

            if (!control.parent) {
                return null;
            }

            // INITIALIZING THE VALIDATOR.
            if (!fcfirst) {

                fcfirst = control;
                fcSecond = control.parent.get(fieldName) as UntypedFormControl;

                if (!fcSecond) {
                    throw new Error('CustomValidators.confirm(): Second control is not found in the parent group!');
                }

                fcSecond.valueChanges.subscribe(() => {
                    fcfirst.updateValueAndValidity();
                });
            }

            if (!fcSecond) {
                return null;
            }

            if (fcSecond.value !== fcfirst.value) {
                return {
                    confirm: true
                };
            }
            return null;
        };
    }

    static password(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => PASSWORD_REGEX.test(control.value) ? null : {password: true};
    }

    static validateIban(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => IBAN_REGEX.test(control.value) ? null : {iban: true};
    }

    static validateBic(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => BIC_REGEX.test(control.value) ? null : {bic: true};
    }

    static validateBankAccTextFields(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => BANK_ACC_TEXT_FIELDS_REGEX.test(control.value)
            ? null
            : {'text-field': true};
    }

    static email(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => EMAIL_REGEX.test(control.value) ? null : {email: true};
    }

    static iban(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value) {
                if (isValidIBANNumber(control.value) === false) {
                    return {
                        iban: true
                    };
                }
                return null;
            }
        };
    }

    static validDate(dateStruct: BirthdayStruct): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let isValid = true;

            if (!control.parent) {
                return null;
            }

            const day = control.parent.get(dateStruct['day']);
            const month = control.parent.get(dateStruct['month']);
            const year = control.parent.get(dateStruct['year']);

            let dependentElement: UntypedFormControl;
            let hasNullDependentContent = true;

            for (const key in dateStruct) {
               if (dateStruct.hasOwnProperty(key)) {
                   dependentElement = control.parent.get(dateStruct[key]) as UntypedFormControl;
                   if (dependentElement.value !== '') {
                       hasNullDependentContent = false;
                       break;
                   }
               }
            }

            if (hasNullDependentContent === true) {
                month.setErrors(null);
                day.setErrors(null);
                year.setErrors(null);
                return null;
            }

            const date = moment(`${year.value}-${month.value}-${day.value}`, 'YYYY-MM-DD', true);
            isValid = date.isValid();

            if (!isValid) {
                year.setErrors({error: true});
            } else {
                month.setErrors(null);
                day.setErrors(null);
                year.setErrors(null);
            }

            return isValid === true ? null : {error: true};
        };
    }

    static conditionalRequiredTrue(fieldName: string): ValidatorFn {
        let currentControl: AbstractControl;
        let conditionalControl: AbstractControl;

        return (control: AbstractControl): ValidationErrors | null => {

            if (!control.parent) {
                return null;
            }

            // INITIALIZING THE VALIDATOR.
            if (!currentControl) {

                currentControl = control;
                conditionalControl = control.parent.get(fieldName) as UntypedFormControl;

                if (!conditionalControl) {
                    throw new Error('CustomValidators.conditionalRequiredTrue(): Second control is not found in the parent group!');
                }

                conditionalControl.valueChanges.subscribe(() => {
                    currentControl.updateValueAndValidity();
                });
            }

            if (!conditionalControl) {
                return null;
            }

            if (conditionalControl.value && currentControl.value !== true) {
                return {
                    required: true
                };
            }
            return null;
        };
    }

    static phone(control: AbstractControl): ValidationErrors | null {
        return PHONE_REGEX.test(control.value) ? null : {phone: true};
    }

    static isGermanIban(control: AbstractControl): ValidationErrors | null {
        let fcfirst: AbstractControl;
        let fcSecond: AbstractControl;
        const errorObj = {isGermanIban: true};

        if (!control.parent) {
            return errorObj;
        }

        // INITIALIZING THE VALIDATOR.
        if (!fcfirst) {

            fcfirst = control;
            fcSecond = control.parent.get('iban') as UntypedFormControl;

            if (!fcSecond) {
                throw new Error('CustomValidators.isGermanIban(): Iban control is not found in the parent group!');
            }

            fcSecond.valueChanges.subscribe(() => {
                fcfirst.updateValueAndValidity();
            });
            if (fcSecond.value == null) {
                return errorObj;
            }
            return (fcSecond.value.toLowerCase().startsWith('de')) ? null : {isGermanIban: true};
        }
    }

    static composeOr(validator1: ValidatorFn, validator2: ValidatorFn): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!validator1 || !validator2) {
                return null;
            }

            const check1 = validator1(control);
            const check2 = validator2(control);

            if (!check1 || !check2) {
                return null;
            } else {
                return _mergeErrors([check1, check2]);
            }
        };
    }

    static countryGermany(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let isObjectSame = false;
            if (!!control.value && control.value.hasOwnProperty('id') && control.value.hasOwnProperty('name')) {
                isObjectSame = control.value.id === COUNTRY_GERMANY.id && control.value.name === COUNTRY_GERMANY.name;
            }

            return isObjectSame ? null : {error: true};
        };
    }

    static DateIsTodayOrAfter(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let year: number;
            let month: number;
            let day: number;

            if (
                control.value &&
                control.value.hasOwnProperty('year') &&
                control.value.hasOwnProperty('month') &&
                control.value.hasOwnProperty('day')
            ) {
                year = control.value.year;
                month = control.value.month - 1;
                day = control.value.day;
            }

            const date: moment.Moment = moment({
                year,
                month,
                day,
            });

            const isTodayOrAfter: boolean = date.isSameOrAfter(moment(), 'day');

            return isTodayOrAfter ? null : {error: true};
        };
    }
}

const isValidIBANNumber = (input): boolean => {
    const CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
    };

    const iban = input.toUpperCase().replace(/[^A-Z0-9]/g, '');
        const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }
    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function(letter) {
        return letter.charCodeAt(0) - 55;
    });

    return mod97(digits);
};

const mod97 = (string): boolean => {
    let checksum = string.slice(0, 2); let fragment;
    for (let offset = 2; offset < string.length; offset += 7) {
        fragment = checksum + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }

    return checksum === 1;
};

function _mergeErrors(arrayOfErrors: ValidationErrors[]): ValidationErrors | null {
    const errs: { [key: string]: any } =
        arrayOfErrors.reduce((res: ValidationErrors | null, errors: ValidationErrors | null) => errors != null
            ? {...res, ...errors}
            : res, {});
    return Object.keys(errs).length === 0 ? null : errs;
}

