import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState, MediaStreamElement } from "../../store/media.state";
import {
    MediaMicMuteAction,
    MediaMicUnmuteAction,
    MediaToggleFullsizedMainVideo,
    MediaVideocamOnAction,
    MediaVideoTrackOffAction,
} from "../../store/media.action";
import { ModalOpenConfirmCloseConnectionAction } from "../../../modal/store/modal.action";
import { fastFadeInOut } from "../../../../animations";
import { Observable, Subscription, fromEvent, merge } from "rxjs";

@Component({
    selector: "app-control-bar",
    templateUrl: "./control-bar.component.html",
    styleUrls: ["./control-bar.component.scss"],
    animations: [fastFadeInOut],
})
export class ControlBarComponent implements OnDestroy, OnInit {
    localStream$: Observable<MediaStreamElement>;
    remoteStream$: Observable<MediaStreamElement>;

    isRemoteActive = false;
    isLocalActive = false;
    public mouseMoved = false;
    mouseOver = false;

    mouseInactiveTimer: ReturnType<typeof setTimeout>;
    mouseLeaveTimer: ReturnType<typeof setTimeout>;

    mouseMoveSubscription: Subscription = null;

    constructor(private store: Store<AppState>) {
        this.localStream$ = store.select(
            (state: AppState) => state.media.localStream
        );
        this.remoteStream$ = store.select(
            (state: AppState) => state.media.remoteStream
        );
    }

    ngOnInit() {
        this.localStream$.subscribe(
            (stream) => (this.isLocalActive = stream.active)
        );
        this.remoteStream$.subscribe(
            (stream) => (this.isRemoteActive = stream.active)
        );

        const moveObservable = fromEvent(document, "mousemove");
        const touchObservable = fromEvent(document, "touchstart");

        this.mouseMoveSubscription = merge(
            touchObservable,
            moveObservable
        ).subscribe((e) => {
            if (this.isRemoteActive === true && this.isLocalActive === true) {
                clearTimeout(this.mouseInactiveTimer);
                this.mouseMoved = true;

                this.mouseInactiveTimer = setTimeout(() => {
                    this.mouseMoved = false;
                }, 3000);
            }
        });
    }

    @HostListener("mouseover")
    onMouseOver() {
        clearTimeout(this.mouseLeaveTimer);
        this.mouseOver = true;
    }

    @HostListener("mouseleave")
    onMouseOut() {
        this.mouseLeaveTimer = setTimeout(() => {
            this.mouseOver = false;
        }, 3000);
    }

    videocamOn() {
        this.store.dispatch(new MediaVideocamOnAction());
    }

    videocamOff() {
        this.store.dispatch(new MediaVideoTrackOffAction());
    }

    callEnd() {
        this.store.dispatch(new ModalOpenConfirmCloseConnectionAction());
    }

    micMute() {
        this.store.dispatch(new MediaMicMuteAction());
    }

    micUnmute() {
        this.store.dispatch(new MediaMicUnmuteAction());
    }

    ngOnDestroy() {
        this.unsubscribeMouseListener();
    }

    unsubscribeMouseListener() {
        if (this.mouseMoveSubscription !== null) {
            this.mouseMoveSubscription.unsubscribe();
        }
    }

    toggleVideoFullScreen() {
        this.store.dispatch(new MediaToggleFullsizedMainVideo());
    }
}
