import { filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/login/login.state';
import { DoTanLoginAction } from '../../store/login/login.action';
import { InvalidDirective } from '../../../../forms/directives/invalid.directive';
import { Observable, Subscription } from 'rxjs';
import { BrandingService, CERTIFIED_SUBDOMAINS, Subdomains } from '../../../branding/providers/branding.service';
import { BrandingState } from '../../../branding/store/branding.state';
import { LEGAL_DATA_PROCESS_TERMS, LEGAL_DATA_SECURITY, LEGAL_TERMS, LOGIN_ROUTE } from '../../../../routes';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-tan-login',
    templateUrl: './tan-login.component.html',
    styleUrls: ['./tan-login.component.scss']
})
export class TanLoginComponent implements OnInit, OnDestroy {
    tanLoginForm: UntypedFormGroup;
    doctorLoginRoute = LOGIN_ROUTE;
    dataSecurityRoute = LEGAL_DATA_SECURITY;
    termsRoute = LEGAL_TERMS;
    dataProcessTermsRoute = LEGAL_DATA_PROCESS_TERMS;

    @ViewChildren(InvalidDirective) invalidDirectives: QueryList<InvalidDirective>;
    public removeAgbCheckbox$: Observable<boolean>;
    public hideDataProcessTerms$: Observable<boolean>;
    public backgroundImg$: Observable<any>;
    public brandingData$: Observable<BrandingState>;
    public showDataSecuritySeal: boolean;
    private readonly agbSubscription: Subscription = null;
    private readonly dataProcessTermsSubscription: Subscription = null;

    constructor(private formBuilder: UntypedFormBuilder,
                private store: Store<AppState>,
                private brandingService: BrandingService,
                private route: ActivatedRoute) {
        this.tanLoginForm = formBuilder.group({
            code1: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
            code2: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
            code3: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
            code4: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
            code5: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
            code6: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
            dataProcessTerm: [null, Validators.compose([Validators.requiredTrue])],
            agbTerm: [null, null]
        }, {validators: [this.dataProcessTermsValidator()]});

        this.brandingData$ = this.brandingService.getBrandingSettingsFromStore().pipe(
            filter(e => e !== null && e !== undefined),
            shareReplay()
        );

        this.removeAgbCheckbox$ = this.brandingData$.pipe(map(e => e.removeAgbCheckbox));
        this.hideDataProcessTerms$ = this.brandingData$.pipe(map(e => e.hideDataProcessTerms));

        this.agbSubscription = this.removeAgbCheckbox$.subscribe(hideCheckbox => {
            if (hideCheckbox) {
                this.tanLoginForm.controls['agbTerm'].setValue(true);
            }
        });

        this.dataProcessTermsSubscription = this.hideDataProcessTerms$.subscribe(hideCheckbox => {
            if (hideCheckbox) {
                this.tanLoginForm.controls['dataProcessTerm'].setValue(true);
            }
        });

        this.backgroundImg$ = this.brandingData$.pipe(
            map(settings => {
                const backgroundImg = settings.bgImage_patientLogin || '/assets/img/general/praxis_bg.jpg';
                return {backgroundImage: backgroundImg};
            }));

        store.select(s => s.login.error).subscribe(httpError => {
            if (httpError) {
                if (httpError.status === 429) {
                    this.tanLoginForm.setErrors({
                        rateLimitReached: true
                    });
                } else {
                    this.tanLoginForm.setErrors({
                        notValid: true
                    });
                }
            }
        });
    }

    ngOnInit(): void {
        this.showDataSecuritySeal = CERTIFIED_SUBDOMAINS.some(
            (subdomain: Subdomains) => subdomain === this.brandingService.getSubdomain(window.location.hostname)
        );

        this.route.queryParams.pipe(
            take(1),
            filter((params: Params) => params && this.isValidTanFromParams(params)),
            tap((params: Params) => this.setCodeControls(params.tan))
        ).subscribe();
    }

    ngOnDestroy(): void {
        if (this.agbSubscription !== null) {
            this.agbSubscription.unsubscribe();
        }

        if (this.dataProcessTermsSubscription !== null) {
            this.dataProcessTermsSubscription.unsubscribe();
        }
    }

    public onSubmit() {
        if (this.tanLoginForm.valid) {
            this.login(this.getTan());
        } else {
            Object.keys(this.tanLoginForm.controls).forEach(key => {
                this.tanLoginForm.get(key).markAsDirty();
            });
            this.invalidDirectives.map((directive) => {
                directive.triggerTooltip();
            });
        }
    }

    public handleBackspace(event) {
        if (!event.target.value) {
            this.goToPreviousInput(event);
        }
    }

    public handleInput(event) {
        if (event.inputType === 'deleteContentBackward') {
            this.goToPreviousInput(event);
        } else {
            this.goToNextInput(event);
        }
    }

    public handleFocus(event) {
        const target = event.srcElement || event.target;
        target.setSelectionRange(0, 9999);
    }

    public goToNextInput(event) {
        const target = event.srcElement || event.target;
        if (target.nextElementSibling) {
            setTimeout(() => {
                target.nextElementSibling.focus();
            }, 5);
        }
    }

    public goToPreviousInput(event) {
        const target = event.srcElement || event.target;
        if (target.previousElementSibling) {
            setTimeout(() => {
                target.previousElementSibling.focus();
            }, 5);
        }
    }

    public onPaste(event) {
        const copiedText = event.clipboardData.getData('Text');
        this.fillTANIntoForm(this.prepareTANString(copiedText));
    }

    private login(tan: string): void {
        this.store.dispatch(new DoTanLoginAction(tan));
    }

    private getTan(): string {
        const value = this.tanLoginForm.value;
        return value.code1 + value.code2 + value.code3 + value.code4 + value.code5 + value.code6;
    }

    private fillTANIntoForm(tan: string) {
        for (let i = 1; i <= 6; i++) {
            this.tanLoginForm.controls['code' + i].setValue(tan[i - 1]);
        }
    }

    private prepareTANString(s: string) {
        return s.replace(' ', '').toUpperCase();
    }

    private isValidTanFromParams(params: Params): boolean {
        return params.tan && params.tan.length === 6;
    }

    private getCodeFormControls(): AbstractControl[] {
        return Object.keys(this.tanLoginForm.controls).reduce((controls: AbstractControl[], controlName: string) => {
            if (controlName.startsWith('code')) {
                controls.push(this.tanLoginForm.controls[controlName]);
            }

            return controls;
        }, []);
    }

    private setCodeControls(tan: string): void {
        const codeFormControl: AbstractControl[] = this.getCodeFormControls();

        tan.split('').forEach((code: string, index: number) => codeFormControl[index].setValue(code));
    }

    private dataProcessTermsValidator(): ValidatorFn {
        // Because the validation messages for both dataProcessTerm and agbTerm controls are the same
        // and visually overlap each other when they are active, this custom validation was created.
        return (fg: UntypedFormGroup): ValidationErrors | null => {
            if (fg.controls['dataProcessTerm'].value === true && fg.controls['agbTerm'].value !== true) {
                if (fg.controls['agbTerm'].valid === true) {
                    fg.controls['agbTerm'].setErrors({'incorrect': true});
                }
            } else {
                fg.controls['agbTerm'].setErrors(null);
            }
            return null;
        };
    }
}
