import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

export class SendFormBuilder {

    public static build(formBuilder: UntypedFormBuilder): UntypedFormGroup {
        return formBuilder.group({
            file: [null, Validators.required]
        });
    }
}
