import {/*webSocket,*/ WebSocketSubject} from 'rxjs/webSocket';
// import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {Injectable} from '@angular/core';


@Injectable()
export class SignalConnection<T> {
    // @TODO: remove this completely
    // private static SIGNAL_SERVER_URL: string = ''; // environment.webSocketUrl;
    private socket$: WebSocketSubject<T | string> = null;
    // private connectors: SignalConnectorInterface[] = [];

    public constructor() {

    }

    public connect(): WebSocketSubject<T | string> {
        // if (this.isClosed()) {
        //     this.socket$ = webSocket(SignalConnection.SIGNAL_SERVER_URL);
        //     this.socket$.subscribe();
        //     this.connectors.forEach(connector => connector.onOpenConnection());
        // } else {
        //     throw new Error('SignalConnection: Invalid state, socket connection all ready exiting!');
        // }
        return this.socket$;
    }

    public reConnect(): WebSocketSubject<T | string> {
        // if (this.isClosed()) {
        //     this.connect();
        // }
        return this.socket$;
    }

    public close() {
        // this.connectors.forEach(connector => connector.onCloseConnection());
        // this.socket$.unsubscribe();
    }

    public register(connector: SignalConnectorInterface) {
        // this.connectors.push(connector);
        // if (!this.isClosed()) {
        //     connector.onOpenConnection();
        // }
    }

    public send(data: T) {
        // this.socket$.next(data);
    }

    public subscribeMessages(next: (value: T) => void): Subscription {
        // return this.socket$.subscribe((msg: T) => next(msg));

        // This is a dummy return value
        return new Subscription();
    }

    private isClosed(): boolean {
        return !this.socket$ || this.socket$.isStopped;
    }
}

export interface SignalConnectorInterface {
    onOpenConnection();

    onCloseConnection();
}
