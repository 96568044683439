import { distinctUntilChanged, filter, map } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AppState } from "../../store/signal.state";
import { Store } from "@ngrx/store";
import {
    SignalConnection,
    SignalConnectorInterface,
} from "../signal.connection";

type Timer = ReturnType<typeof setInterval>;

/**
 *
 *  A Connection initialisation: Session start
 *  ----------------------------------------------------------------------------------------------------------------------------------------
 *    I am                                  |     |  Server                        |     |  Peer
 *  ........................................................................................................................................
 *    {identify: {                          | --> | (add this to client session)   |     |
 *        localToken: ['my1', 'my'],        |     |                                |     |
 *        remoteToken: 'remote1'}           |     |                                |     |
 *    }                                     |     |                                |     |
 *
 *  B1 Observe Peers is not online
 *  ----------------------------------------------------------------------------------------------------------------------------------------
 *    I am                                  |     |  Server                        |     |  Peer
 *  ........................................................................................................................................
 *    {isOnline: {                          | --> | (add this to client session)   |     |
 *        token: ['remote1', 'remote2'],    |     |                                |     |
 *        bIgnoreReady: true | false        |     |                                |     |
 *    }                                     |     |                                |     |
 *  ........................................|.....|................................|.....|..................................................
 *                                          | <-- | {isOnlineAndReady: {           |     |
 *                                          |     |     time: 0,                   |     |
 *                                          |     |     bIgnoreReady: true | false |     |
 *                                          |     | }                              |     |
 *
 *  B2 Observe Peers is online
 *  ----------------------------------------------------------------------------------------------------------------------------------------
 *    I am                                  |     |  Server                        |     |  Peer
 *  ........................................................................................................................................
 *    {isOnline: {                          | --> | (add this to client session)   |     |
 *        token: ['remote1', 'remote2'],    |     |                                |     |
 *        bIgnoreReady: true | false        |     |                                |     |
 *    }                                     |     |                                |     |
 *  ........................................|.....|................................|.....|..................................................
 *                                          | <-- | {isOnlineAndReady: {           |     |
 *                                          |     |     time: 2000,                |     |
 *                                          |     |     bIgnoreReady: true | false |     |
 *                                          |     | }                              |     |
 *                                          |     |                                |     |
 *                                          |     | If bIgnoreReady === true       |     |
 *                                          |     | ========================       |     |
 *                                          |     | {isOnlineAndReady: {           | --> |
 *                                          |     |     time: 2000,                |     |
 *                                          |     |     bIgnoreReady: true         |     |
 *                                          |     | }                              |     |
 *  ........................................|.....|................................|.....|..................................................
 *                                          |     |                                |     |
 *  isOnlineResponse?: {                    | --> | -----------------------------> | --> |
 *       token: string[], time: 2001        |     |                                |     |
 *  };                                      |     |                                |     |
 *                                          | <-- | <----------------------------- | <-- | isOnlineResponse?: {
 *                                          |     |                                |     |    token: string[], time: 2001
 *                                          |     |                                |     | };
 */
export interface OnlineStateMessage {
    // outgoing messages -------------------------------------------------
    identify?: { localToken: string[]; remoteToken: string };
    isOnline?: { token: string[]; bIgnoreReady: boolean };

    // incoming messages -------------------------------------------------
    isOnlineAndReady?: { time: number; bIgnoreReady: boolean };

    // outgoing and incoming message -------------------------------------
    isOnlineResponse?: { token: string[]; time: number };
}

export interface OnlineStateEvent {
    userToken: string[];
    online: boolean;
}

@Injectable()
export class OnlineStateConnector implements SignalConnectorInterface {
    private peerReady = false;
    private subject$: BehaviorSubject<OnlineStateEvent> = new BehaviorSubject({
        userToken: [],
        online: false,
    });
    private session: OnlineSession;

    constructor(
        private connection: SignalConnection<OnlineStateMessage>,
        private store: Store<AppState>
    ) {
        connection.register(this);
        this.store
            .select((s) => s.signal)
            .pipe(
                filter((signal) => signal !== undefined && signal != null),
                filter(
                    (signal) =>
                        signal.myTokens !== null && signal.userTokens !== null
                )
            )
            .subscribe((signal) => {
                this.session = new OnlineSession(
                    signal.myTokens,
                    signal.userTokens,
                    signal.activeUserToken,
                    signal.activePollingTimer
                );
                this.peerReady =
                    signal.ready !== undefined
                        ? signal.ready.isLocalConnectionReady
                        : this.peerReady;
            });
    }

    public onOpenConnection() {
        this.connection.subscribeMessages(this.handle.bind(this));
    }

    public onCloseConnection() {
        if (!this.session) {
            return;
        }

        const timer = this.getCurrentTimer();
        this.stopObserveUsers(timer);
    }

    public loadSession(userToken: string): string {
        this.connection.send(<OnlineStateMessage>{
            identify: {
                localToken: this.session.myTokens,
                remoteToken: userToken,
            },
        });
        return userToken;
    }

    public startObserveUsers(time = 3000): Timer {
        return setInterval(
            () => this.sendOverwatchMessage(this.session.userTokens),
            time
        );
    }

    public stopObserveUsers(timer: Timer) {
        clearInterval(timer);
    }

    public getCurrentTimer(): Timer {
        return this.session.activePollingTimer;
    }

    // #####################################################################################################################################

    public isUserOnline(token: string): Observable<OnlineStateEvent> {
        return this.subject$.pipe(
            filter((e: OnlineStateEvent) => e.userToken.indexOf(token) >= 0)
        );
    }

    public getCountOnlinePeers(): Observable<any> {
        const states = {};
        return this.subject$.pipe(
            map((state) => {
                states[state.userToken[0]] = state.online;
                return states;
            }),
            map((state) => {
                let count = 0;
                Object.keys(state)
                    .filter((key) => state[key])
                    .map((e) => count++);
                return count;
            }),
            distinctUntilChanged()
        );
    }

    public setReady(isReady: boolean) {
        this.peerReady = isReady;
    }

    // Handle Signal server events
    private handle(message: OnlineStateMessage) {
        // Update my online state
        if (message.isOnlineAndReady) {
            this.onIsOnlineAndReady(this.peerReady, message.isOnlineAndReady);
        }

        if (message.isOnlineResponse) {
            if (message.isOnlineResponse.time > 0) {
                this.subject$.next({
                    userToken: this.toArray(message.isOnlineResponse.token),
                    online: true,
                });
            } else {
                this.subject$.next({
                    userToken: this.toArray(message.isOnlineResponse.token),
                    online: false,
                });
            }
        }
    }

    private toArray(obj: any): Array<any> {
        if (!Array.isArray(obj)) {
            return [obj];
        } else {
            return obj;
        }
    }

    private sendOverwatchMessage(tokens: string[]) {
        // my tokens
        return this.connection.send(<OnlineStateMessage>{
            isOnline: { token: tokens, bIgnoreReady: this.peerReady },
        });
    }

    private onIsOnlineAndReady(
        peerReady: boolean,
        message: { time: number; bIgnoreReady: boolean }
    ) {
        if (peerReady) {
            this.connection.send(<OnlineStateMessage>{
                isOnlineResponse: {
                    token: this.session.myTokens,
                    time: message.time,
                },
            });
        } else {
            this.connection.send(<OnlineStateMessage>{
                isOnlineResponse: { token: this.session.myTokens, time: 0 },
            });
        }
    }
}

class OnlineSession {
    constructor(
        readonly myTokens: string[],
        readonly userTokens: string[],
        readonly activeUserToken: string,
        readonly activePollingTimer: Timer
    ) {}
}
